.container {
  height: var(--header-height);
}

.inner {
  background-color: var(--color-panel-solid);
  border: var(--card-border-width) solid transparent;
  height: var(--header-height);
  z-index: 2;
}

.inner::after {
  inset: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
  content: '';
  box-shadow: 0 0 0 1px var(--gray-a6);
  box-shadow: 0 0 0 1px color-mix(in oklab, var(--gray-a6), var(--gray-6) 25%);
}
